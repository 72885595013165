<template>
  <div id="app">
    <router-view></router-view>

  </div>
</template>

<script>
import webConfig from "@/config/web.config/index";
import { setmMxpanelUserInfo, setmMxpanelValue } from "@/utils/mixpanel"
// import { StatsigClient } from '@statsig/js-client'
// import { runStatsigSessionReplay } from '@statsig/session-replay';
// import { runStatsigAutoCapture } from '@statsig/web-analytics';
import { getFirestoreDataByUid } from "@/utils/firebase"
import { transferSubscription, getSubscriptionList } from "./api/system";
export default {
  name: "App",
  created() {
    //读取onboarding本地数据
    let onBoardingRecordInfo = window.sessionStorage.getItem('onBoardingRecordInfo');
    if (onBoardingRecordInfo) {
      this.$store.commit('updateOnBoardingRecordInfo', JSON.parse(onBoardingRecordInfo));
    }
    // 生产环境禁用 console.log
    if (webConfig.NODE_ENV === 'production') {
      console.log = () => { };
    }
  },
  mounted() {
    // const officialAccountUid = 'jsLrYH9nTNVB2M2zoGC7lDQotUt1'
    // const sourceUid = 'jsLrYH9nTNVB2M2zoGC7lDQotUt1'
    // getSubscriptionList(sourceUid).then(({ data }) => {


    //   if (data && data.length) {
    //     const curSubscriptionData = data.find(item => item.active == 1);
    //     transferSubscription(officialAccountUid, { sourceUid, subscriptionId: curSubscriptionData.subscriptionId })

    //   }


    // })

    // getFirestoreDataByUid('Khn5PU5n22a915U9wgUILPmBsHG2').then(res => {
    //   window.sessionStorage.setItem('userInfos', JSON.stringify(res))
    //   console.log(res);

    // })
    // const mixpanelDeviceId = window.sessionStorage.getItem("mixpanelDeviceId");
    // //录屏
    // const myStatsigClient = new StatsigClient(
    //   webConfig.statsigId,
    //   { userID: mixpanelDeviceId }
    // );
    // console.log(myStatsigClient._user.userID);
    // runStatsigSessionReplay(myStatsigClient);
    // runStatsigAutoCapture(myStatsigClient);
    // await myStatsigClient.initializeAsync();
    const urlParams = this.$utils.getUrlParams(window.location.href);
    console.log(urlParams);
    window.sessionStorage.setItem('urlQuery', JSON.stringify(urlParams));
    // 设置mixpanel用户属性
    let userInfo = {
      user_source: "web",
      user_type: "new",
      web_version: webConfig.webVersion,
      special_offer: urlParams.special_offer ? urlParams.special_offer : '',
    }
    // 需要检查的参数列表
    const utmParams = ['utm_source', 'utm_medium', 'utm_campaign'];
    // 动态添加存在的 UTM 参数
    utmParams.forEach(param => {
      if (urlParams[param]) {
        userInfo[param] = urlParams[param];
      }
    });
    setmMxpanelUserInfo(
      userInfo
    )
    document.body.addEventListener('touchstart', function () { }); //移动端:active伪类无效的解决方法
    // 阻止双击放大页面
    document.addEventListener('dblclick', function (event) {
      event.preventDefault();
    }, { passive: false });
  },

};
</script>